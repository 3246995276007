/* eslint-disable no-throw-literal */
import * as axiosStatic from "axios";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import fetchJsonp from "fetch-jsonp";
import { isNullOrEmpty } from "../../components/shared/common/util";
import { ApplicationConstants } from "../../models/ApplicationConstants";

/**
 * Base wrapper implementation for axios that does not provide an implementation for the passthrough to axios.
 * @class
 */
export class AxiosWrapperBase {
    /** axios instance to call through to.
     */
    private axios: axiosStatic.AxiosStatic;

    /**
     * Initializes a new instance of the `AxiosWrapperBase` class.
     * @constructor
     * @param {axiosStatic.AxiosStatic} axios Axios service instance.
     */
    constructor(axios?: axiosStatic.AxiosStatic) {
        this.axios = (axios || axiosStatic) as axiosStatic.AxiosStatic;
    }

    /**
     * Method to send any type of http call (get/post/put/etc.).
     * @method
     * @param config {axiosStatic.RequestOptions} The request detail.
     * @returns {Promise<AxiosResponse>} The result of the call.
     */
    public sendRequest(config: AxiosRequestConfig): Promise<AxiosResponse> {
        if (!config) {
            throw "config must not be null";
        }
    
        let headerConfig = {};
        const isAuthorizationEnabled = process.env.REACT_APP_AUTHORIZATION_ENABLED === "true";
    
        const refreshTokenPromise = isAuthorizationEnabled
            ? this.refreshTokenIfNeeded() // Refresh token only if enabled
            : Promise.resolve(); // No-op if authorization is disabled
    
        return refreshTokenPromise
            .then(() => {
                // Prepare headers after ensuring token is refreshed
                Object.assign(headerConfig, config.headers, {
                    CorrelationId: sessionStorage.getItem("correlationId"),
                    UserEmail: sessionStorage.getItem("userEmail"),
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_NEXTGEN_APIKEY,
                    ...(isAuthorizationEnabled && sessionStorage.getItem("userToken") && { 'Authorization': `Bearer ${sessionStorage.getItem("userToken")}` })
                });
    
                config = {
                    ...config,
                    headers: headerConfig,
                };
    
                this.onRequest(config);
                let response = this.axios(config) as Promise<AxiosResponse>;
                
                this.onResponse(response);
                return response;
            })
            .catch((error) => {
                console.error("API Request Failed:", error);
                throw error;
            });
    }
    

    /**
     * Convenient alias for doing a get service call.
     * @method
     * @param url {string} The url to make the service call to.
     * @param config {AxiosRequestConfig} Additional settings for the service call.
     * @returns {Promise<AxiosResponse>} The result of the call.
     */
    public get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.callWithMergedConfig("get", url, config);
    }

    /**
     * Convenient alias for doing a delete service call.
     * @method
     * @param url {string} The url to make the service call to.
     * @param config {AxiosRequestConfig} Additional settings for the service call.
     * @returns {Promise<AxiosResponse>} The result of the call.
     */
    public delete(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.callWithMergedConfig("delete", url, config);
    }

    /**
     * Convenient alias for doing a head service call.
     * @method
     * @param url {string} The url to make the service call to.
     * @param config {AxiosRequestConfig} Additional settings for the service call.
     * @returns {Promise<AxiosResponse>} The result of the call.
     */
    public head(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.callWithMergedConfig("head", url, config);
    }

    /**
     * Convenient alias for doing a post service call.
     * @method
     * @param url {string} The url to make the service call to.
     * @param data {any} The data to send as the body of the request.
     * @param config {AxiosRequestConfig} Additional settings for the service call.
     * @returns {Promise<AxiosResponse>} The result of the call.
     */
    public post(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.callWithMergedConfigWithData("post", url, data, config);
    }

    /**
     * Convenient alias for doing a put service call.
     * @method
     * @param url {string} The url to make the service call to.
     * @param data {any} The data to send as the body of the request.
     * @param config {AxiosRequestConfig} Additional settings for the service call.
     * @returns {Promise<AxiosResponse>} The result of the call.
     */
    public put(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.callWithMergedConfigWithData("put", url, data, config);
    }

    /**
     * Convenient alias for doing a patch service call.
     * @method
     * @param url {string} The url to make the service call to.
     * @param data {any} The data to send as the body of the request.
     * @param config {AxiosRequestConfig} Additional settings for the service call.
     * @returns {Promise<AxiosResponse>} The result of the call.
     */
    public patch(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.callWithMergedConfigWithData("patch", url, data, config);
    }

    /**
     * Overridable method to do pre-processing before the request is sent.
     * @method
     * @param config {axiosStatic.RequestOptions} The request detail.
     */
    protected onRequest(config: AxiosRequestConfig): void { } // tslint:disable-line

    /**
     * Overridable method to do post-processing after the response is received.
     * @method
     * @param result {Promise<AxiosResponse>} The response detail.
     * @returns {Promise<AxiosResponse>} The result of the call with any required changes added.
     */
    protected onResponse(result: Promise<AxiosResponse>): Promise<AxiosResponse> {
        return result;
    }

    private refreshTokenIfNeeded(): Promise<void> {
        const refreshTokenUrl = process.env.REACT_APP_RSLR_REFRESH_TOKEN || "";
        const pcApiTimeout = Number(process.env.REACT_APP_PCAPI_TIMEOUT_MS);
        const logoutUrl = process.env.REACT_APP_RSLR_LOGOUT_API_URI;
    
        return new Promise((resolve, reject) => {
            const expiresOn = sessionStorage.getItem("userTokenExpiry") || new Date().getTime();
            const isNearExpiry =
                new Date().getTime() + (ApplicationConstants.AccessTokenRefreshTimeInMinutes * 60 * 1000) >
                new Date(expiresOn).getTime();
    
            if (!isNearExpiry) {
                resolve(); // Token is still valid; no action needed
                return;
            }
    
            fetchJsonp(refreshTokenUrl, { timeout: pcApiTimeout })
                .then((response) => response.json())
                .then((tokenResponse) => {
                    if (!isNullOrEmpty(tokenResponse)) {
                        sessionStorage.setItem("userToken", tokenResponse["AccessToken"]);
                        sessionStorage.setItem("userTokenExpiry", tokenResponse["ExpiresOn"]);
                        resolve(); // Successfully refreshed
                    } else {
                        throw new Error("Empty token response");
                    }
                })
                .catch((ex) => {
                    console.error("Token refresh failed:", ex);
                    sessionStorage.clear();
                    window.open(logoutUrl, "_self");
                    reject(ex); // Propagate error
                });
        });
    }

    
    /**
     * Merge the given method and url into the given config object and call through to axios.
     * @method
     * @param method {string} The type of http call to make.
     * @param url {string} The url to make the service call to.
     * @param config {AxiosRequestConfig} The request detail.
     * @returns {Promise<AxiosResponse>} The combined configuration.
     */
    private callWithMergedConfig(method: string, url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        var mergedConfig = Object.assign({}, config, {
            method: method,
            url: url
        });

        return this.sendRequest(mergedConfig);
    }

    /**
     * Merge the given method, url and data into the given config object and call through to axios.
     * @method
     * @param method {string} The type of http call to make.
     * @param url {string} The url to make the service call to.
     * @param data {any} The data to send as the body of the request.
     * @param config {AxiosRequestConfig} The request detail.
     * @returns {Promise<AxiosResponse>} The combined configuration.
     */
    private callWithMergedConfigWithData(method: string, url: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {

        if (!url) {
            throw "url must not be null";
        }

        var mergedConfig = Object.assign({}, config, {
            method: method,
            url: url,
            data: data
        });

        return this.sendRequest(mergedConfig);
    }
}