/**
 * Application Constants.
 * @constant
 */
export const 
ApplicationConstants = {
    microsoftEmail: "@microsoft.com",

    //Customer Search Constants
    CustomerSearchSuggesterName: "mdppsuggester",
    CustomerSearchSelectColumn: "OrganizationName",
    TopSearchSuggesstions: 50,
    IsFuzzy: false,
    CustomerSearchCountryGlobalIndex: "Country",
    CustomerSearchCascadeCountryGlobalIndex: "CascadeCountry",
    CustomerSearchIndex: "customersearch-index",
    CRMChildAccountFacetName: "CRMChildAccountName",
    AddressFacetName: "AddressLine1",
    CityFacetName: "City",
    PostalCodeFacetName: "PostalCode",
    StateFacetName: "StateProvince",
    CRMIDFacetName: "CRMID",
    ChildCRMIDFacetName: "ChildCRMID",
    TPIDFacetName: "TPID",
    GPIDFacetName: "GPID",
    DunsNumberFacetName: "DunsNumber",
    CustomerSearchFacetNames: ['CRMChildAccountName', 'AddressLine1', 'City', 'PostalCode', 'StateProvince', 'ChildCRMID', 'TPID', 'GPID', 'DunsNumber'],
    DealCustomerDomain: "dealCustomerDomain",
    
    //Product Search Constants
    ProductSearchSuggesterName: "productsuggester",
    DistinctProductSearchSuggesterName: "distinctproductsuggester",
    ProductSearchSelectColumn: "PfamName",
    ProductSearchCountryGlobalIndex: "Country",
    ProductSearchIndex: "productsearch-index",
    ProductSearchProductType: "ProductType",
    DistinctProductSearchIndex: "distinctproductsearch-index",
    ProductSearchApplicableDeals: "ApplicableDealsCollection",
    ProductSearchProductTypeId: "ProductTypeId",
    ProductSearchGlobalFilter: "ProductSearchGlobalFilter",
    SpecialPricingApplicableDeal: 2,
    DealRegistrationApplicableDeal: 1,
    CPStrategicApplicableDeal: 4,
    OTLEApplicableDeal: 5,
    ProductFamilyCodeFacetName: "ProductFamilyCode",
    ProductFamilyNameFacetName: "PfamName",
    BusinessUnitFacetName: "BusinessUnit",
    SkuNameFacetName: "SkuName",
    SkuPartNumberFacetName: "SkuPartNumber",
    CountryFacetName: "Country",
    InternalPricingProgramProductSearchFacetNames: ['ProductFamilyCode', 'PfamName', 'BusinessUnit', 'SkuName', 'SkuPartNumber', 'Country'],
    InternalPricingProgramDistinctProductSearchFacetNames: ['ProductFamilyCode', 'PfamName', 'BusinessUnit', 'Country'],
    ProductSearchFacetNames: ['PfamName', 'BusinessUnit', 'SkuName', 'SkuPartNumber'],
    DistinctProductSearchFacetNames: ['PfamName', 'BusinessUnit'],
    BusinessUnitProductSearchSuggesterName: "businessunitsuggester",
    BusinessUnitProductSearchIndex: "businessunitproductsearch-index",
    BusinessUnitSearchSelectColumn: "BusinessUnit",
    ProductSearchChannelType: "ChannelType",
    DirectChannelType: 1,
    IndirectChannelType: 2,
    InternalDealVisibility: "InternalDealVisibility",
    ExternalDealVisibility: "ExternalDealVisibility",
    UnitsCommitted: "UnitsCommitted",
    Quantity: "quantity",
    OTLESelectedProductsGridName: "Selected Product(s) for One-time Local Empowerment",

    //Search Constants
    CognitiveSearchType: "simple",
    CognitiveSearchMode: "all",
    CognitiveSearchModeAny: "any",
    CognitiveSearchMaxResults: 1000,
    LegalEntitySelectionUnitstransact: "unitsCommitted",
    LegalEntitySelectionIsPrimary: "selected",
    LegalEntityUploadDealVolumn: "DealVolume",
    NumericEditorType: "numeric",
    QueryKey: "5A55E1313C812847ECC5B70BFD84A578",
    CustomerSearchServiceEnv: "customersearch-dev",
    FacetCount: 100,
    DefaultUserRole: "MSS",
    LoggedinUserRole: "Owner",
    DefaultDashBoardCaseView: "CP Strategic Case View",
    CPstrategicPageName: "cpstrategic",
    OpportunityNumberParamName: "oppNumber",
    OpportunityDealNumberParamName: "oppDealNumber",
    IsEUEFTA: "isEUEFTA",
    DebugParam: "debug",

    DecisionTypeName: "decisionTypeName",
    BusinessUnitName: "businessUnitName",
    PfamName: "name",
    PfamCode: "productFamilyCode",
    ProductFamilyName: "productFamilyName",
    DiscountValueApiField: "discountValue",
    DiscountTitle: "Discount %",
    CoreDiscountTitle: "Core Discount %",
    BoosterDiscountTitle: "New Customer Booster Discount %",
    TotalDiscountTitle: "Total Discount %",
    DiscountAmountTitle: "Discount Amount",
    Country: "COUNTRY",
    MinLengthForResellerName: 3,
    CaseCopyCutOffInMonths: 3,
    AllVertical: "ALL",
    EditPendingReview: "Edit Pending Review",
    DeniedStatus: "Denied",
    DealPeriodDate: "dealPeriodStartDate",
    ApprovedStatus: "Approved",
    ExpiredStatus: "Expired",
    LegalEntityType: "legalEntityType",
    ADRDMPResellerType: "ADR/DMP",
    PartnerType: "partnerType",
    CustomerSearchTopThreshold: 1000,
    MiddleEastRegion: "Middle East",
    SaudiArabia: "Saudi Arabia",
    MiddleEastCountry: "Middle East",
    Egypt: "Egypt",
    UAE: "United Arab Emirates",
    SouthAfrica: "South Africa",
    AndOperator: "and",
    OrOperator: "or",
    Eu: "EU",
    Eq: "eq",
    Ne: "ne",
    PricingProgramCustomerSearchIndex: "pricingprogramcustomersearch-index",
    PricingProgramCustomerCustomerSuggesterName: "mdppcustomersuggester",
    CustomerSearchFilterFacetNames: ['DunsNumber'],
    LegalEntityName: "legalEntityName",

    // Pricing Program Dashboard Names
    SpecialPricing: "Special Pricing",
    DealRegistration: "Deal Registration",
    OTLE: "OTLE",
    CPStrategic: "CP Strategic",
    CPStrategicCases: "Custom Pricing Strategic Cases",
    AllCases: "All Cases",
    productInformationGrid: "Product Information View",
    SPLocalEmpowermentCases:"Special Pricing (Local Empowerment)",
    OTLECases:"One Time Local Empowerment",

    // Case action constants
    DRThresholdQuantity: 1,
    SPThresholdPercentage: 70,

    //bidGrid Excel Static Columns
    skuName:"SkuName",
    skuNumber:"SkuNumber",
    EnLocaleForGrid: "en",

    EnLocaleForGridForMoreRecord: "en-EN",
    // General constant
    Cases: "Cases",
    ProductInformationforSpecialPricing: "Product Information for Special Pricing",
    SPLocalEmpowermentTitle: "Special Pricing (Local Empowerment)",
    Chatbot: "chatbot",

    //Dashboard Routes
    ProductViewSPRoute: "/dashboard/productviewsp",
    SpecialPricingRoute: "/dashboard/specialpricing",
    DealRegistrationRoute: "/dashboard/dealregistration",
    CPStrategicRoute: "/dashboard/cpstrategic",
    AllCasesRoute: "/dashboard/allcases",
    SPLocalEmpowerment:"/dashboard/splocalempowerment",
    OTLERoute:"/dashboard/otle",
    PricingProgramAllDistinctCustomerSearchIndex: "distinctcustomersearch-index",
    PricingProgramAllDistinctCustomerSearchIndexApiUrl:'/indexes/distinctcustomersearch-index/docs/search?api-version=2020-06-30',
    PricingProgramCustomerSearchIndexApiUrl: '/indexes/pricingprogramcustomersearch-index/docs/search?api-version=2020-06-30',
    ProductSearchIndexApi_Url: '/indexes/productsearch-index/docs/search?api-version=2020-06-30',
    DistinctProductSearchIndexApiUrl: '/indexes/distinctproductsearch-index/docs/search?api-version=2020-06-30',
    CustomerSearchIndexApiUrl:'/indexes/customersearch-index/docs/search?api-version=2020-06-30',
    BuProductSearchApiUrl:'/indexes/businessunitproductsearch-index/docs/search?api-version=2020-06-30',
    StatusCodeColumnName: "statusCodeName",
    ClosureDecisionColumnName: "closureDecisionString",
    recordsToFetch: 1000,
    otlePageName: "otle",

    AccessTokenRefreshTimeInMinutes: 5,
}